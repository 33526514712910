<template>
  <v-btn class="ml-0" min-width="0" text to="/app/reference/production_facility" exact>
    <v-icon>mdi-hydraulic-oil-temperature</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "DefaultGoObjects",
};
</script>
